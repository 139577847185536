const sidebar = document.querySelector('.c-sidepanel');
const sidebarOpen = document.querySelector('.js-openbtn');
const sidebarClose = sidebar.querySelector('.js-clsbtn');
const menuItems = sidebar.querySelectorAll('.c-vmenu__item a');
const menuTours = sidebar.querySelector('#menu-tours');
const menuCars = sidebar.querySelector('#menu-cars');

function closeSidebar() {
  sidebar.classList.remove('c-sidepanel--open');
  menuTours.checked = false;
  menuCars.checked = false;
}

sidebarOpen.addEventListener('click', () => {
  sidebar.classList.add('c-sidepanel--open');
});

sidebarClose.addEventListener('click', () => {
  closeSidebar();
});

menuItems.forEach(item => {
  item.addEventListener('click', () => {
    closeSidebar();
  });
});

menuTours.addEventListener('change', () => {
  if (menuTours.checked) {
    menuCars.checked = false;
  }
});

menuCars.addEventListener('change', () => {
  if (menuCars.checked) {
    menuTours.checked = false;
  }
});
