export default function scrollTo(to, duration, adjust) {
  let scrollToObj;
  let scrollToPosition;
  let scrollDuration;

  if (typeof to === 'string') {
    scrollToObj = document.querySelector(to);

    if (scrollToObj && typeof scrollToObj.getBoundingClientRect === 'function') {
      scrollToPosition = window.pageYOffset + scrollToObj.getBoundingClientRect().top;
    } else {
      throw new Error(`No element found with the selector "${to}"`);
    }
  } else if (to instanceof Element) {
    scrollToPosition = window.pageYOffset + to.getBoundingClientRect().top;
  } else if (typeof to === 'number') {
    scrollToPosition = to;
  } else {
    scrollToPosition = 0;
  }

  if (scrollToPosition > adjust) {
    scrollToPosition -= adjust;
  }

  if (typeof duration !== 'number' || duration < 0) {
    scrollDuration = 1000;
  } else {
    scrollDuration = duration;
  }

  const cosParameter = (window.pageYOffset - scrollToPosition) / 2;
  let scrollCount = 0;
  let oldTimestamp = window.performance.now();

  function step(newTimestamp) {
    let tsDiff = newTimestamp - oldTimestamp;

    if (tsDiff > 100) {
      tsDiff = 30;
    }

    scrollCount += Math.PI / (scrollDuration / tsDiff);

    if (scrollCount >= Math.PI) {
      return;
    }

    const moveStep = Math.round(scrollToPosition + cosParameter + cosParameter * Math.cos(scrollCount));
    window.scrollTo(0, moveStep);
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }

  window.requestAnimationFrame(step);
};

// Performance.now() polyfill
(function() {
  if ('performance' in window === false) {
    window.performance = {};
  }

  Date.now =
    Date.now ||
    function() {
      // thanks IE8
      return new Date().getTime();
    };

  if ('now' in window.performance === false) {
    let nowOffset = Date.now();

    if (performance.timing && performance.timing.navigationStart) {
      nowOffset = performance.timing.navigationStart;
    }

    window.performance.now = function() {
      return Date.now() - nowOffset;
    };
  }
})();
