import tippy from 'tippy.js';
import { throttle } from 'underscore';
import scrollTo from './scrollto';

require('./sidebar');

tippy('.js-tooltip', {
  arrow: false,
  distance: 5,
  maxWidth: 300,
  appendTo: document.body,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  },
  theme: 'safari'
});

document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});


window.addEventListener(
  'scroll',
  throttle(() => {
    const scrollUp = document.querySelector('.js-scroll-up');

    if (window.pageYOffset > window.innerHeight * 1.2) {
      scrollUp.style.opacity = '0.8';
    } else {
      scrollUp.style.opacity = '0';
    }
  }, 10)
);

document.querySelector('.js-scroll-up').addEventListener('click', () => {
  scrollTo(0, 2000);
});
